.checkboxGroupWrapper {
  display: flex;
  align-items: center;
  width: auto;
  flex-wrap: nowrap;

  & > div {
    margin-right: 21px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.checkboxGroupWrapperBlock {
  height: auto;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    margin-right: 0;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.checkboxGroupMessage {
  margin-top: 15px !important;
}
