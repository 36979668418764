.checkboxWrapper {
  position: relative;
}

.checkboxBlock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkboxInput {
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked + .checkboxLabel {
    &:before {
      border: 2px solid var(--forest-green);
      background-color: var(--forest-green);
    }

    &:after {
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) scale(1);
    }
  }

  &:indeterminate + .checkboxLabel {
    &:before {
      border: 2px solid var(--forest-green);
      background-color: var(--forest-green);
    }

    &:after {
      border: solid white;
      border-width: 0 2px 0 0;
      transform: rotate(90deg) scale(1);
      top: 1px;
    }
  }

  &:disabled + .checkboxLabel {
    color: var(--submarine);

    &:before {
      border: 2px solid var(--porcelain);
    }
  }

  &:disabled:checked + .checkboxLabel {
    color: var(--submarine);

    &:before {
      border: 2px solid var(--de-york);
      background-color: var(--de-york);
    }

    &:after {
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) scale(1);
    }
  }

  &:disabled:indeterminate + .checkboxLabel {
    color: var(--submarine);

    &:before {
      border: 2px solid var(--de-york);
      background-color: var(--de-york);
    }

    &:after {
      border: solid white;
      border-width: 0 2px 0 0;
      transform: rotate(90deg) scale(1);
      top: 0;
    }
  }

  &:focus + .checkboxLabel {
    &:before {
      border: 2px solid var(--rolling-stone);
    }
  }
}

.checkboxLabelBlock {
  position: relative;
}

.checkboxLabel {
  display: block;
  position: relative;
  padding: 0 0 0 30px;
  font-size: var(--title-default-size);
  line-height: var(--title-default-height);
  color: var(--shark);
  cursor: pointer;
  transition: 0.15s all ease;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid var(--submarine);
    border-radius: 2px;
    background-color: transparent;
    transition: 0.15s all ease;
    box-sizing: border-box;
  }

  &:after {
    content: " ";
    position: absolute;
    top: 1px;
    left: 6px;
    display: block;
    width: 6px;
    height: 12px;
    opacity: 1;
    background-color: transparent;
  }

  &:hover {
    &:before {
      border: 2px solid var(--rolling-stone);
    }
  }
}

.checkboxError {
  &:before {
    border-color: var(--cinnabar);
  }
}
.checkboxNoWrap {
  white-space: nowrap;
}

.checkboxMessage {
  padding-left: 30px;
}
